<template>
  <div>
    <!-- Initials Block -->
    <div 
      class="bg-holgates-lightGreen-75 
      absolute top-[60px] 
      right-0 
      rounded-full 
      text-white 
      text-center 
      h-[36px] w-[36px] 
      text-lg 
      m-6 
      flex 
      items-center 
      justify-center 
      cursor-pointer"
      @click="toggleMenu"
    >
      {{ $attrs.initials }}
    </div>

    <div>
      <!-- Dropdown Menu -->
      <transition name="fade">
        <div 
          v-if="menuOpen" 
          @click="toggleMenu"
          class="absolute top-[120px] right-3 bg-white shadow-md rounded-lg p-3 w-48 z-50 border menu-container"
        >
          <!-- Triangle Indicator -->
          <div class="menu-triangle"></div>

          <ul class="space-y-2 text-gray-700">
            <li class="cursor-pointer hover:bg-gray-100 p-2">
              <nuxt-link class="no-underline" to="/account/preferences">Email Preferences</nuxt-link>
            </li>
            <li class="cursor-pointer hover:bg-gray-100 p-2" @click="resetPassword">
              <nuxt-link class="no-underline" to="/account/password">Reset Password</nuxt-link>
            </li>
            <li class="cursor-pointer p-2  hover:bg-gray-100" @click="logout">
              Logout
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
const { $toast } = useNuxtApp();
const supabase = useSupabaseClient();
const router = useRouter();
const menuOpen = ref(false);
const booking = useBookingStore();

// Toggle menu visibility
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

const logout = async () => {
  booking.reset();
  await supabase.auth.signOut();
  $toast.success("Logged out successfully", { timeout: 2000 });
  window.location.href = "/"; // Redirect to home after logout
};
</script>

<style scoped>
/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Triangle (caret) above the menu */
.menu-triangle {
  position: absolute;
  top: -8px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white; /* Matches menu background */
}
</style>
